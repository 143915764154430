var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "information-tab-container",
      attrs: { "grid-list-md": "", "text-xs-center": "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md3: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "tab-card listbox" },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          class: _vm.active === "main" ? "active" : "",
                          attrs: { avatar: "" },
                          on: {
                            click: function($event) {
                              _vm.active = "main"
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-icon", { attrs: { color: "grey" } }, [
                                _vm._v("mdi-fingerprint")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s("Algemene gegevens")
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.canSeePrices
                        ? _c(
                            "v-list-item",
                            {
                              class: _vm.active === "price" ? "active" : "",
                              attrs: { avatar: "" },
                              on: {
                                click: function($event) {
                                  _vm.active = "price"
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-icon", { attrs: { color: "grey" } }, [
                                    _vm._v("mdi-cash")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: { textContent: _vm._s("Prijzen") }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { md9: "", xs12: "" } },
            [
              _vm.active === "main"
                ? _c(
                    "v-card",
                    { staticClass: "tab-card" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { disabled: !_vm.edit, "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Project", required: "" },
                            model: {
                              value: _vm.unit.project.name,
                              callback: function($$v) {
                                _vm.$set(_vm.unit.project, "name", $$v)
                              },
                              expression: "unit.project.name"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Kamer Type", required: "" },
                            model: {
                              value: _vm.unit.type,
                              callback: function($$v) {
                                _vm.$set(_vm.unit, "type", $$v)
                              },
                              expression: "unit.type"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: { label: "Kamer Nummer", required: "" },
                            model: {
                              value: _vm.unit.name,
                              callback: function($$v) {
                                _vm.$set(_vm.unit, "name", $$v)
                              },
                              expression: "unit.name"
                            }
                          }),
                          _vm.unit.owner
                            ? _c("v-text-field", {
                                attrs: {
                                  label: "Huidige eigenaar",
                                  required: ""
                                },
                                model: {
                                  value: _vm.unit.owner.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.unit.owner, "name", $$v)
                                  },
                                  expression: "unit.owner.name"
                                }
                              })
                            : _c("v-text-field", {
                                attrs: {
                                  label: "Huidige eigenaar",
                                  required: ""
                                },
                                model: {
                                  value: _vm.tempNoContractsYet,
                                  callback: function($$v) {
                                    _vm.tempNoContractsYet = $$v
                                  },
                                  expression: "tempNoContractsYet"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.active === "price"
                ? _c(
                    "v-card",
                    { staticClass: "tab-card" },
                    [
                      _c("price-panel", {
                        attrs: { unit: _vm.unit },
                        on: {
                          save: function($event) {
                            return _vm.$emit("save", _vm.unit)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.active === "company"
                ? _c(
                    "v-card",
                    { staticClass: "tab-card" },
                    [_c("company-panel", { attrs: { unit: _vm.unit } })],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }