var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "mb-9" }, [_vm._v("Eigenaars")]),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.unit.owners,
          "item-key": "email"
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function(ref) {
              var items = ref.items
              return [
                _c(
                  "tbody",
                  _vm._l(items, function(item) {
                    return _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(
                          _vm._s(_vm._f("formatDate")(item.startDate)) + " "
                        )
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(_vm._f("formatDate")(item.endDate)) + " ")
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(item.name) + " ")
                      ])
                    ])
                  }),
                  0
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }