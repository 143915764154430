var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "headline" }, [
          _vm._v("Aanpassen/Toevoegen gebruiker")
        ])
      ]),
      _vm.editedItem
        ? _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Voornaam",
                              required: ""
                            },
                            model: {
                              value: _vm.editedItem.firstName,
                              callback: function($$v) {
                                _vm.$set(_vm.editedItem, "firstName", $$v)
                              },
                              expression: "editedItem.firstName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Achternaam",
                              required: ""
                            },
                            model: {
                              value: _vm.editedItem.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.editedItem, "lastName", $$v)
                              },
                              expression: "editedItem.lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "email address",
                              required: ""
                            },
                            model: {
                              value: _vm.editedItem.email,
                              callback: function($$v) {
                                _vm.$set(_vm.editedItem, "email", $$v)
                              },
                              expression: "editedItem.email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            staticClass: "filter-select",
                            attrs: {
                              outlined: "",
                              "hide-details": "",
                              small: "",
                              label: "Rol",
                              items: _vm.roles
                            },
                            model: {
                              value: _vm.editedItem.role,
                              callback: function($$v) {
                                _vm.$set(_vm.editedItem, "role", $$v)
                              },
                              expression: "editedItem.role"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        { staticClass: "d-flex justify-space-between" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "red darken-1", text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("delete", _vm.editedItem)
                }
              }
            },
            [_vm._v(" Verwijderen ")]
          ),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("save", _vm.editedItem)
                    }
                  }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }