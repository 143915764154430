var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("span", { staticClass: "headline" }, [_vm._v("Prijs aanpassen")])
      ]),
      _vm.editedItem
        ? _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { staticClass: "price-edit-container" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12", lg: "6" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu1",
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "auto"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Start datum",
                                                  "persistent-hint": "",
                                                  "prepend-icon": "mdi-calendar"
                                                },
                                                model: {
                                                  value: _vm.dateFormatted,
                                                  callback: function($$v) {
                                                    _vm.dateFormatted = $$v
                                                  },
                                                  expression: "dateFormatted"
                                                }
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                726661960
                              ),
                              model: {
                                value: _vm.menu1,
                                callback: function($$v) {
                                  _vm.menu1 = $$v
                                },
                                expression: "menu1"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "" },
                                on: {
                                  input: function($event) {
                                    _vm.menu1 = false
                                  }
                                },
                                model: {
                                  value: _vm.date,
                                  callback: function($$v) {
                                    _vm.date = $$v
                                  },
                                  expression: "date"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12", lg: "6" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu2",
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "auto"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Eind datum",
                                                  "persistent-hint": "",
                                                  "prepend-icon": "mdi-calendar"
                                                },
                                                model: {
                                                  value: _vm.endDateFormatted,
                                                  callback: function($$v) {
                                                    _vm.endDateFormatted = $$v
                                                  },
                                                  expression: "endDateFormatted"
                                                }
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1759950977
                              ),
                              model: {
                                value: _vm.menu2,
                                callback: function($$v) {
                                  _vm.menu2 = $$v
                                },
                                expression: "menu2"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { "no-title": "" },
                                on: {
                                  input: function($event) {
                                    _vm.menu2 = false
                                  }
                                },
                                model: {
                                  value: _vm.endDate,
                                  callback: function($$v) {
                                    _vm.endDate = $$v
                                  },
                                  expression: "endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.payments, function(payment) {
                    return _c(
                      "v-row",
                      { key: payment.id },
                      [
                        _vm.editedItem[payment.id]
                          ? [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      value: _vm.editedItem[payment.id].status,
                                      label: payment.label,
                                      color: "black",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.editedItem[payment.id].status,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedItem[payment.id],
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem[payment.id].status"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.editedItem[payment.id].status
                                ? [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "12",
                                          md: "12"
                                        }
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            outlined: "",
                                            label: "Betalingsmethode",
                                            items: _vm.paymentTypes,
                                            "item-value": "id",
                                            "item-text": "label",
                                            required: ""
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.editedItem[
                                                payment.id
                                              ].company = _vm.getCompanies(
                                                _vm.editedItem[payment.id]
                                                  .paymentMethod
                                              )[0]
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.editedItem[payment.id]
                                                .paymentMethod,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editedItem[payment.id],
                                                "paymentMethod",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editedItem[payment.id].paymentMethod"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: payment.id === "rent" ? 12 : 6,
                                          md: payment.id === "rent" ? 12 : 6
                                        }
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            outlined: "",
                                            label: "Company / Eigenaar",
                                            items: _vm.getCompanies(
                                              _vm.editedItem[payment.id]
                                                .paymentMethod
                                            ),
                                            "item-value": "_id",
                                            "item-text": "name",
                                            required: ""
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.onCompanyChange(
                                                _vm.editedItem[payment.id]
                                                  .companyId,
                                                payment.id
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.editedItem[payment.id]
                                                .companyId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editedItem[payment.id],
                                                "companyId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editedItem[payment.id].companyId"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    payment.id === "rent"
                                      ? _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "6"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                outlined: "",
                                                label: "Bedrag blijver",
                                                type: "number",
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.editedItem[payment.id]
                                                    .stayAmount,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem[payment.id],
                                                    "stayAmount",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "editedItem[payment.id].stayAmount"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "6" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            outlined: "",
                                            label:
                                              payment.id == "rent"
                                                ? "Bedrag nieuwkomer"
                                                : "Bedrag",
                                            type: "number",
                                            required: ""
                                          },
                                          model: {
                                            value:
                                              _vm.editedItem[payment.id].amount,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editedItem[payment.id],
                                                "amount",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "editedItem[payment.id].amount"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.editedItem[payment.id].paymentMethod !==
                                      "recurring" &&
                                    _vm.editedItem[payment.id].paymentMethod !==
                                      "oneTimePayment"
                                      ? _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "6"
                                            }
                                          },
                                          [
                                            _vm.editedItem[payment.id]
                                              .bankAccount
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    outlined: "",
                                                    disabled: "",
                                                    label: "Rekeningnummer",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editedItem[payment.id]
                                                        .bankAccount,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.editedItem[
                                                          payment.id
                                                        ],
                                                        "bankAccount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedItem[payment.id].bankAccount"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.editedItem[payment.id].paymentMethod !==
                                      "recurring" &&
                                    _vm.editedItem[payment.id].paymentMethod !==
                                      "oneTimePayment"
                                      ? _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              sm: "6",
                                              md: "6"
                                            }
                                          },
                                          [
                                            _vm.editedItem[payment.id]
                                              .currentOwner
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    outlined: "",
                                                    disabled: "",
                                                    label:
                                                      "Naam rekeninghouder",
                                                    required: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editedItem[payment.id]
                                                        .currentOwner,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.editedItem[
                                                          payment.id
                                                        ],
                                                        "currentOwner",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editedItem[payment.id].currentOwner"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" Annuleren ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: { click: _vm.saveClicked }
            },
            [_vm._v(" Opslaan ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }